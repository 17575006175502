<template>
    <div class="HeadingMobile">
      <div class="HeadingMobile_left">
        <h5>{{ $i18n.t(`cpm_${currentTab}`) }}</h5>
      </div>
      <img src="@/assets/logos/VidasPrimeLogo.svg" />
    </div>
</template>
<script>
export default {
  props: {},
  computed: {
    currentTab() {
      return this.$store.getters['ui/getCurrentTab'];
    },
  },
  components: {},
};
</script>
<style lang="sass" scoped>
    @import './HeadingMobile.sass'
</style>
